<template>
  <div>
    <loader v-show='loading'></loader>
    <div v-show='!loading'>
      <iframe :src='url' frameborder='0' v-if='url.length > 0' @load='onMyFrameLoad()'></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paymentZk',
  props: {
    dealObj: {
      type: Object,
      default() {
          return {
            DlCode: '00',
            CurPay: 0,
            ClientPhone: '',
            ClientFIO: '',
            Is_pdl: 0
          }
        },
    },
  },
  data() {
    return {
      descInfo: '',
      loading: true,
      zkInfo: {
        account: '', //Счёт/номер карты/номер договора/номер телефона и тп
        payee: '',//ФИО получателя
        amount: '500',//сумма платежа
        date: '',//дата в формате yyyy-mm-dd
        phone: '',//номер телефона плательщика
        flowType: 'MINIMALISTIC'//тип флоу. Возможные значения DEFAULT, CONFIRM_ONLY, SILENT, MINIMALISTIC
      },
      url: '',
    }
  },
  components: {
    loader: () => import('@/components/other/loader'),
  },
  watch: {
    'dealObj.CurPay': {
      handler() {
        this.createUrl()
      }
    }
  },
  methods: {
    onMyFrameLoad() {
      this.loading = false;
    },
    returnPdlStatus() {
      return new Promise((resolve, reject) => {
        this.$myApi.singleRequest({
          action: 'returnPdlStatus',
          type: 'pdl',
          demand: parseInt(this.dealObj.DlCode, 10)
        })
          .then(res => { console.log(res); resolve(res) })
          .catch(res => { console.log(res); reject() })
      });
    },
    createUrl() {
      this.zkInfo.flowType = this.dealObj.Is_pdl == 1 ? 'MINIMALISTIC' : 'DEFAULT';

      this.zkInfo.date = Intl.DateTimeFormat('en-CA').format(new Date());
      this.zkInfo.phone = this.dealObj.ClientPhone;
      this.zkInfo.payee = this.dealObj.ClientFIO;
      this.zkInfo.amount = this.dealObj.CurPay;
      this.zkInfo.account = this.dealObj.DlCode;
      let partnerToken = '';
      this.$myApi.singleRequest({
        action: 'getPrefillZk',
        phone: this.zkInfo.phone,
        payee: this.zkInfo.payee,
        amount: this.zkInfo.amount,
        account: this.zkInfo.account,
        date: this.zkInfo.date,
        flowType: this.zkInfo.flowType
      }).then((res) => {
        partnerToken = res.partnerToken;
        this.url = 'https://koronapay.com/repayment/online/finmoll?prefill=' +
          encodeURIComponent(JSON.stringify(this.zkInfo)) +
          '&partnerToken=' + partnerToken;
      }).catch((res) => {

      });


    },
    loadingDAtaLostil() {
      if (this.dealObj.DlCode != undefined) {
        this.createUrl();
      } else {
        setTimeout(() => {
          this.loadingDAtaLostil();
        }, 500)
      }
    }
  },
  mounted() {
    if (this.dealObj.DlCode != undefined) {
      this.createUrl();
    } else {
      this.$store.dispatch('updateContentData').then(() => {
        this.loadingDAtaLostil();
      })
    }
  }
}
</script>

<style scoped>
iframe{width: 100%;height: 900px}
</style>
